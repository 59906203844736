// 业绩取值方案
import * as API from '@/api/index'

export default {

  //业绩取值方案查询
  PerformanceSchemeAll: params => {
    return API.POST('api/performanceScheme/all', params)
  },
  //业绩取值方案查询(不加分页)
  PerformanceSchemeValid: params => {
    return API.POST('api/performanceScheme/valid', params)
  },
  //业绩取值方案添加
  PerformanceSchemeCreate: params => {
    return API.POST('api/performanceScheme/create', params)
  },
  //业绩取值方案修改
  PerformanceSchemeUpdate: params => {
    return API.POST('api/performanceScheme/update', params)
  },
  //业绩取值方案修改
  PerformanceSchemeDetail: params => {
    return API.POST('api/performanceScheme/detail', params)
  },
  // 业绩取值方案产品详情
  PerformanceSchemeProductAll: params => {
    return API.POST('api/performanceSchemeProduct/all', params)
  },
  // 业绩取值方案产品保存
  PerformanceSchemeProductCreate: params => {
    return API.POST('api/performanceSchemeProduct/create', params)
  },
  // 业绩取值方案项目详情
  PerformanceSchemeProjectAll: params => {
    return API.POST('api/performanceSchemeProject/all', params)
  },
  // 业绩取值方案项目保存
  PerformanceSchemeProjectCreate: params => {
    return API.POST('api/performanceSchemeProject/create', params)
  },
  // 业绩取值方案通用次卡详情
  PerformanceSchemeGeneralCardAll: params => {
    return API.POST('api/performanceSchemeGeneralCard/all', params)
  },
  // 业绩取值方案通用次卡保存
  PerformanceSchemeGeneralCardCreate: params => {
    return API.POST('api/performanceSchemeGeneralCard/create', params)
  },
  // 业绩取值方案时效卡详情
  PerformanceSchemeTimeCardAll: params => {
    return API.POST('api/performanceSchemeTimeCard/all', params)
  },
  // 业绩取值方案时效卡保存
  PerformanceSchemeGeneralTimeCardCreate: params => {
    return API.POST('api/performanceSchemeTimeCard/create', params)
  },
  // 业绩取值方案储值卡详情
  PerformanceSchemeSavingCardAll: params => {
    return API.POST('api/performanceSchemeSavingCard/all', params)
  },
  // 业绩取值方案储值卡保存
  PerformanceSchemeGeneralSavingCardCreate: params => {
    return API.POST('api/performanceSchemeSavingCard/create', params)
  },
  // 业绩取值方案消耗产品详情
  PerformanceSchemeTreatProductAll: params => {
    return API.POST('api/performanceSchemeTreatProduct/all', params)
  },
  // 业绩取值方案消耗产品保存
  PerformanceSchemeGeneralTreatProductCreate: params => {
    return API.POST('api/performanceSchemeTreatProduct/create', params)
  },
  // 业绩取值方案消耗项目详情
  PerformanceSchemeTreatProjectAll: params => {
    return API.POST('api/performanceSchemeTreatProject/all', params)
  },
  // 业绩取值方案消耗项目保存
  PerformanceSchemeGeneralTreatProjectCreate: params => {
    return API.POST('api/performanceSchemeTreatProject/create', params)
  },
}
