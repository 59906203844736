<template>
  <div class="content_body  SalaryPerformanceScheme">
    <!-- 头部 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="handleSearch">
            <el-form-item label="业绩取值方案">
              <el-input v-model="searchData.performanceName" size="small" placeholder="输入业绩取值方案搜索" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="searchData.isValidity" placeholder="选择有效性" clearable size="small" @change="handleSearch">
                <el-option label="有效" :value="true"></el-option>
                <el-option label="无效" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="handleShow('newDialogVisible')"  v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="martp_10">
      <el-table size="small" :data="tableData" v-loading="loading">
        <el-table-column prop="Name" label="业绩取值方案"></el-table-column>
        <el-table-column prop="Active" :formatter="(row)=>row.Active ? '有效' : '无效'" label="有效性">
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="handleShow('editDialogVisible',scope.row)" v-prevent-click>编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
    <!-- 新增业绩取值方案弹出层 -->
    <el-dialog title="新增业绩取值方案" :visible.sync="newDialogVisible" width="30%" @close="handleClose('newDialogVisible', 'ruleForm')">
      <el-form size="small" :model="ruleForm" ref="ruleForm" label-width="110px" class="demo-ruleForm">
        <el-form-item label="业绩取值方案" prop="PerformancePlan" :rules="[
      { required: true, message: '请输入业绩取值方案名称', trigger: 'blur' }]" label-width="110px">
          <el-input v-model="ruleForm.PerformancePlan"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="newDialogVisible=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('newDialogVisible')" :loading="faSaveLoading">保 存</el-button>
      </span>
    </el-dialog>
    <!--编辑业绩取值方案弹出层-->
    <el-dialog title="编辑业绩取值方案" :visible.sync="editDialogVisible" width="1100px" custom-class="editDialog" @close="handleClose('editDialogVisible')">
      <el-form size="small" :inline="true" :model="EditFormData" ref="EditFormData" label-width="120px">
        <el-form-item label="业绩取值方案" prop="Name" :rules="[
      { required: true, message: '请输入业绩取值方案名称', trigger: 'blur' }]">
          <el-input v-model="EditFormData.Name"></el-input>
        </el-form-item>
        <el-form-item label="有效性">
          <el-radio-group v-model="EditFormData.Active">
            <el-radio :label="true">有效</el-radio>
            <el-radio :label="false">无效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="销售-产品" name="first">
          <el-table :data="GoodsCategoryCommission.ProductCategory" size="small" max-height="480px" row-key="CategoryID" :tree-props="{ children: 'Child' }" v-loading="PerformanceSchemeDetailLoading">
            <el-table-column prop="CategoryName" label="产品分类"></el-table-column>
            <el-table-column label="现金业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3 
                  @input="royaltyRateChange(1, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡抵扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceRate" v-enter-number3
                  @input="royaltyRateChange(2, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceLargessRate"
                  v-enter-number3 @input="royaltyRateChange(3, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button v-if="scope.row.ParentID" type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,1)">
                  产品业绩</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="销售-项目" name="second">
          <el-table :data="GoodsCategoryCommission.ProjectCategory" size="small" max-height="450px" row-key="CategoryID" :tree-props="{ children: 'Child' }">
            <el-table-column prop="CategoryName" label="项目分类"></el-table-column>
            <el-table-column label="现金业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(1, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡抵扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(2, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceLargessRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(3, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button v-if="scope.row.ParentID" type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,2)">
                  项目业绩</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="销售-储值卡" name="third">
          <el-table :data="GoodsCategoryCommission.SavingCardCategory" size="small" max-height="450px" row-key="CategoryID" :tree-props="{ children: 'Child' }">
            <el-table-column prop="CategoryName" label="储值卡分类"></el-table-column>
            <el-table-column label="现金业绩">
              <template slot-scope="scope">
                <el-col :span="12">
                  <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3
                    class="input_type" @input="royaltyRateChange(1, scope.row)">
                    <template slot="append">%</template>
                  </el-input>
                </el-col>

              </template>
            </el-table-column>
            <el-table-column label="操作" width="115px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,3)">储值卡业绩
                </el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
        <el-tab-pane label="销售-时效卡" name="fourth">
          <el-table :data="GoodsCategoryCommission.TimeCardCategory" size="small" max-height="450px" row-key="CategoryID" :tree-props="{ children: 'Child' }">
            <el-table-column prop="CategoryName" label="商品分类" width="180px" fixed></el-table-column>
            <el-table-column prop="PayRate" label="现金业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(1, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="PayRate" label="卡抵扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(2, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="PayRate" label="赠送卡扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceLargessRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(3, scope.row)">
                  <template slot="append">%</template>
                </el-input>

              </template>
            </el-table-column>
            <el-table-column label="操作" width="115px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,4)">时效卡业绩
                </el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
        <el-tab-pane label="销售-通用次卡" name="five">
          <el-table :data="GoodsCategoryCommission.GeneralCardCategory" size="small" max-height="450px" row-key="CategoryID" :tree-props="{ children: 'Child' }">
            <el-table-column prop="CategoryName" label="商品分类"></el-table-column>
            <el-table-column prop="PayRate" label="现金业绩">
              <template slot-scope="scope">

                <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(1, scope.row)">
                  <template slot="append">%</template>
                </el-input>

              </template>
            </el-table-column>
            <el-table-column prop="PayRate" label="卡抵扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(2, scope.row)">
                  <template slot="append">%</template>
                </el-input>

              </template>
            </el-table-column>
            <el-table-column prop="PayRate" label="赠送卡扣业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceLargessRate"
                  v-enter-number3 class="input_type" @input="royaltyRateChange(3, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="125px">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,5)">通用次卡业绩
                </el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
        <el-tab-pane label="消耗-产品" name="six">
          <el-table :data="GoodsCategoryCommission.TreatProductCategory" size="small" max-height="450px" row-key="CategoryID" :tree-props="{ children: 'Child' }">
            <el-table-column prop="CategoryName" label="商品分类"></el-table-column>
            <el-table-column label="非赠送业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(4, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PerformanceLargessRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(5, scope.row)">
                  <template slot="append">%</template>
                </el-input>

              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button v-if="scope.row.ParentID" type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,6)">
                  产品业绩</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
        <el-tab-pane label="消耗-项目" name="seven">
          <el-table :data="GoodsCategoryCommission.TreatProjectCategory" size="small" max-height="450px" row-key="CategoryID" :tree-props="{ children: 'Child' }">
            <el-table-column prop="CategoryName" label="商品分类"></el-table-column>
            <el-table-column label="非赠送业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(4, scope.row)">
                  <template slot="append">%</template>
                </el-input>

              </template>
            </el-table-column>
            <el-table-column label="赠送业绩">
              <template slot-scope="scope">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PerformanceLargessRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(5, scope.row)">
                  <template slot="append">%</template>
                </el-input>

              </template>
            </el-table-column>

            <el-table-column label="操作" width="100px">
              <template slot-scope="scope">
                <el-button v-if="scope.row.ParentID" type="primary" size="mini" @click="handleShow('dialogDetailVisible', scope.row,7)">
                  项目业绩</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editDialogVisible = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" :loading="saveLoading" @click="handleSave('editDialogVisible')" v-prevent-click>保 存
        </el-button>
      </div>
    </el-dialog>
    <!-- 商品明细业绩弹出层 -->
    <el-dialog :title="GoodsTitle" :visible.sync="dialogDetailVisible" width="1000px" custom-class="editDialog" @close="handleClose('dialogDetailVisible')">
      <el-table :data="goodsPerformanceList" size="small" max-height="450px">
        <el-table-column prop="Name" :label="goodsCategory"></el-table-column>
        <template v-if="activeName !='six' && activeName!='seven' && activeName !='third'">
          <el-table-column prop="PayRate" label="现金业绩">
            <template slot-scope="scope">
              <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3
                @input="royaltyRateChange(1, scope.row)">
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="PayRate" label="卡抵扣业绩">
            <template slot-scope="scope">

              <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceRate" v-enter-number3
                @input="royaltyRateChange(2, scope.row)">
                <template slot="append">%</template>
              </el-input>

            </template>
          </el-table-column>
          <el-table-column prop="PayRate" label="赠送卡扣业绩">
            <template slot-scope="scope">

              <el-input type="number" size="mini" min="0" v-model="scope.row.SavingCardPerformanceLargessRate" v-enter-number3
                @input="royaltyRateChange(3, scope.row)">
                <template slot="append">%</template>
              </el-input>

            </template>
          </el-table-column>
        </template>
        <template v-else-if="activeName =='third'">
          <el-table-column prop="PayRate" label="现金业绩">
            <template slot-scope="scope">
              <el-col :span="12">
                <el-input type="number" size="mini" min="0" v-model="scope.row.PayPerformanceRate" v-enter-number3
                  class="input_type" @input="royaltyRateChange(1, scope.row)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>

            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column prop="PayRate" label="非赠送业绩">
            <template slot-scope="scope">
              <el-input type="number" size="mini" min="0" v-model="scope.row.PerformanceRate" v-enter-number3
                class="input_type" @input="royaltyRateChange(4, scope.row)">
                <template slot="append">%</template>
              </el-input>

            </template>
          </el-table-column>
          <el-table-column prop="PayRate" label="赠送业绩">
            <template slot-scope="scope">
              <el-input type="number" size="mini" min="0" v-model="scope.row.PerformanceLargessRate" class="input_type" v-enter-number3
                @input="royaltyRateChange(5, scope.row)">
                <template slot="append">%</template>
              </el-input>

            </template>
          </el-table-column>
        </template>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogDetailVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('dialogDetailVisible')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/KHS/Salary/performanceScheme";
export default {
  name: "SalaryPerformanceScheme",

  components: {},

  directives: {},

  data() {
    return {
      loading: false,
      faSaveLoading:false,
      saveLoading: false,
      PerformanceSchemeDetailLoading: false,
      searchData: {
        performanceName: "",
        isValidity: true,
      },
      tableData: [],
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },

      editDialogVisible: false,
      newDialogVisible: false,
      dialogDetailVisible: false,
      GoodsCategoryCommission: {},
      ruleForm: {
        PerformancePlan: "",
      },
      EditFormData: {
        ID: "",
        Name: "",
        Active: true,
        ProductCategory: [],
        ProjectCategory: [],
        GeneralCardCategory: [],
        TimeCardCategory: [],
        SavingCardCategory: [],
        TreatProductCategory: [],
        TreatProjectCategory: [],
      },
      activeName: "first",
      GoodsTitle: "",
      goodsCategory: "",
      goodsPerformanceID: "",
      goodsPerformanceList: [],
    };
  },

  mounted() {
    const that = this;
    that.handleSearch();
  },

  methods: {
    // 业绩取值方案详情
    PerformanceSchemeDetail(id) {
      const that = this;
      const params = {
        ID: id,
      };
      that.PerformanceSchemeDetailLoading = true;
      API.PerformanceSchemeDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.GoodsCategoryCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.PerformanceSchemeDetailLoading = false;
        });
    },
    // 比例
    royaltyRateChange: function (index, row) {
      if (index == 1) {
        if (row.PayPerformanceRate > 100) {
          row.PayPerformanceRate = 100;
        }
      } else if (index == 2) {
        if (row.SavingCardPerformanceRate > 100) {
          row.SavingCardPerformanceRate = 100;
        }
      } else if (index == 3) {
        if (row.SavingCardPerformanceLargessRate > 100) {
          row.SavingCardPerformanceLargessRate = 100;
        }
      } else if (index == 4) {
        if (row.PerformanceRate > 100) {
          row.PerformanceRate = 100;
        }
      } else if (index == 5) {
        if (row.PerformanceLargessRate > 100) {
          row.PerformanceLargessRate = 100;
        }
      }
    },

    //   搜索
    handleSearch() {
      this.paginations.page = 1;
      this.PerformanceSchemeAll();
    },
    // 获取方案列表
    PerformanceSchemeAll() {
      const that = this;
      const params = {
        Name: that.searchData.performanceName,
        Active: that.searchData.isValidity,
        PageNum: that.paginations.page,
      };
      that.loading = true;
      API.PerformanceSchemeAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 分页
    handleCurrentChange() {
      this.PerformanceSchemeAll();
    },

    // 点击tab
    handleTabClick(e) {
      const that = this;
      that.activeName = e.name;
    },
    // 新增保存
    handleNewAdd() {
      const that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.faSaveLoading=true
          API.PerformanceSchemeCreate({
            Name: that.ruleForm.PerformancePlan,
          }).then((res) => {
            if (res.StateCode == 200) {
              that.$message.success({
                message: "添加成功",
                duration: 2000,
              });
              that.handleSearch();
            } else {
              that.$message.success({
                message: res.Message,
                duration: 2000,
              });
            }
          }).finally(()=>{
            that.faSaveLoading=false
          })
        }
      });
    },

    // 编辑保存
    handleEditSave() {
      const that = this;
      that.saveLoading = true;
      // 销售产品
      that.GoodsCategoryCommission.ProductCategory.forEach((item) => {
        let param = {
          CategoryID: item.CategoryID,
          PayPerformanceRate: item.PayPerformanceRate,
          SavingCardPerformanceRate: item.SavingCardPerformanceRate,
          SavingCardPerformanceLargessRate:
            item.SavingCardPerformanceLargessRate,
          Child: [],
        };
        if (
          item.PayPerformanceRate ||
          item.SavingCardPerformanceLargessRate ||
          item.SavingCardPerformanceRate
        ) {
          if (item.Child && item.Child.length) {
            item.Child.forEach((item) => {
              if (
                item.PayPerformanceRate ||
                item.SavingCardPerformanceLargessRate ||
                item.SavingCardPerformanceRate
              ) {
                param.Child.push({
                  CategoryID: item.CategoryID,
                  PayPerformanceRate: item.PayPerformanceRate,
                  SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                  SavingCardPerformanceLargessRate:
                    item.SavingCardPerformanceLargessRate,
                });
              }
            });
          }
          that.EditFormData.ProductCategory.push(param);
        } else if (item.Child && item.Child.length) {
          item.Child.forEach((item) => {
            if (
              item.PayPerformanceRate ||
              item.SavingCardPerformanceLargessRate ||
              item.SavingCardPerformanceRate
            ) {
              param.Child.push({
                CategoryID: item.CategoryID,
                PayPerformanceRate: item.PayPerformanceRate,
                SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                SavingCardPerformanceLargessRate:
                  item.SavingCardPerformanceLargessRate,
              });
            }
          });
          if (param.Child.length) {
            that.EditFormData.ProductCategory.push(param);
          }
        }
      });
      // 销售项目
      that.GoodsCategoryCommission.ProjectCategory.forEach((item) => {
        let param = {
          CategoryID: item.CategoryID,
          PayPerformanceRate: item.PayPerformanceRate,
          SavingCardPerformanceRate: item.SavingCardPerformanceRate,
          SavingCardPerformanceLargessRate:
            item.SavingCardPerformanceLargessRate,
          Child: [],
        };
        if (
          item.PayPerformanceRate ||
          item.SavingCardPerformanceLargessRate ||
          item.SavingCardPerformanceRate
        ) {
          if (item.Child && item.Child.length) {
            item.Child.forEach((item) => {
              if (
                item.PayPerformanceRate ||
                item.SavingCardPerformanceLargessRate ||
                item.SavingCardPerformanceRate
              ) {
                param.Child.push({
                  CategoryID: item.CategoryID,
                  PayPerformanceRate: item.PayPerformanceRate,
                  SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                  SavingCardPerformanceLargessRate:
                    item.SavingCardPerformanceLargessRate,
                });
              }
            });
          }
          that.EditFormData.ProjectCategory.push(param);
        } else if (item.Child && item.Child.length) {
          item.Child.forEach((item) => {
            if (
              item.PayPerformanceRate ||
              item.SavingCardPerformanceLargessRate ||
              item.SavingCardPerformanceRate
            ) {
              param.Child.push({
                CategoryID: item.CategoryID,
                PayPerformanceRate: item.PayPerformanceRate,
                SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                SavingCardPerformanceLargessRate:
                  item.SavingCardPerformanceLargessRate,
              });
            }
          });
          if (param.Child.length) {
            that.EditFormData.ProjectCategory.push(param);
          }
        }
      });
      // 销售通用次卡
      that.GoodsCategoryCommission.GeneralCardCategory.forEach((item) => {
        if (
          item.PayPerformanceRate ||
          item.SavingCardPerformanceLargessRate ||
          item.SavingCardPerformanceRate
        ) {
          let param = {
            CategoryID: item.CategoryID,
            PayPerformanceRate: item.PayPerformanceRate,
            SavingCardPerformanceRate: item.SavingCardPerformanceRate,
            SavingCardPerformanceLargessRate:
              item.SavingCardPerformanceLargessRate,
          };
          that.EditFormData.GeneralCardCategory.push(param);
        }
      });
      // 销售时效卡
      that.GoodsCategoryCommission.TimeCardCategory.forEach((item) => {
        if (
          item.PayPerformanceRate ||
          item.SavingCardPerformanceLargessRate ||
          item.SavingCardPerformanceRate
        ) {
          let param = {
            CategoryID: item.CategoryID,
            PayPerformanceRate: item.PayPerformanceRate,
            SavingCardPerformanceRate: item.SavingCardPerformanceRate,
            SavingCardPerformanceLargessRate:
              item.SavingCardPerformanceLargessRate,
          };
          that.EditFormData.TimeCardCategory.push(param);
        }
      });
      // 销售储值卡
      that.GoodsCategoryCommission.SavingCardCategory.forEach((item) => {
        if (item.PayPerformanceRate) {
          let param = {
            CategoryID: item.CategoryID,
            PayPerformanceRate: item.PayPerformanceRate,
          };
          that.EditFormData.SavingCardCategory.push(param);
        }
      });
      // 消耗产品
      that.GoodsCategoryCommission.TreatProductCategory.forEach((item) => {
        let param = {
          CategoryID: item.CategoryID,
          PerformanceRate: item.PerformanceRate,
          PerformanceLargessRate: item.PerformanceLargessRate,
          Child: [],
        };
        if (item.PerformanceRate || item.PerformanceLargessRate) {
          if (item.Child && item.Child.length) {
            item.Child.forEach((item) => {
              if (item.PerformanceRate || item.PerformanceLargessRate) {
                param.Child.push({
                  CategoryID: item.CategoryID,
                  PerformanceRate: item.PerformanceRate,
                  PerformanceLargessRate: item.PerformanceLargessRate,
                });
              }
            });
          }
          that.EditFormData.TreatProductCategory.push(param);
        } else if (item.Child && item.Child.length) {
          item.Child.forEach((item) => {
            if (item.PerformanceRate || item.PerformanceLargessRate) {
              param.Child.push({
                CategoryID: item.CategoryID,
                PerformanceRate: item.PerformanceRate,
                PerformanceLargessRate: item.PerformanceLargessRate,
              });
            }
          });
          if (param.Child.length) {
            that.EditFormData.TreatProductCategory.push(param);
          }
        }
      });
      // 消耗项目
      that.GoodsCategoryCommission.TreatProjectCategory.forEach((item) => {
        let param = {
          CategoryID: item.CategoryID,
          PerformanceRate: item.PerformanceRate,
          PerformanceLargessRate: item.PerformanceLargessRate,
          Child: [],
        };
        if (item.PerformanceRate || item.PerformanceLargessRate) {
          if (item.Child && item.Child.length) {
            item.Child.forEach((item) => {
              if (item.PerformanceRate || item.PerformanceLargessRate) {
                param.Child.push({
                  CategoryID: item.CategoryID,
                  PerformanceRate: item.PerformanceRate,
                  PerformanceLargessRate: item.PerformanceLargessRate,
                });
              }
            });
          }
          that.EditFormData.TreatProjectCategory.push(param);
        } else if (item.Child && item.Child.length) {
          item.Child.forEach((item) => {
            if (item.PerformanceRate || item.PerformanceLargessRate) {
              param.Child.push({
                CategoryID: item.CategoryID,
                PerformanceRate: item.PerformanceRate,
                PerformanceLargessRate: item.PerformanceLargessRate,
              });
            }
          });
          if (param.Child.length) {
            that.EditFormData.TreatProjectCategory.push(param);
          }
        }
      });
      API.PerformanceSchemeUpdate(that.EditFormData)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handleSearch();
            that.$message.success({
              message: "保存成功",
              duration: 2000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.saveLoading = false;
        });
    },

    // 商品明细业绩获取
    goodsPerformance(type, row) {
      const that = this;
      const params = {
        PerformanceSchemeID: that.EditFormData.ID,
        CategoryID: row.CategoryID,
      };
      let api;
      switch (type) {
        case 1:
          that.GoodsTitle =
            "产品销售业绩取值" +
            " - " +
            row.ParentName +
            " - " +
            row.CategoryName;
          that.goodsCategory = "产品名称";
          api = "PerformanceSchemeProductAll";
          break;
        case 2:
          that.GoodsTitle =
            "项目销售业绩取值" +
            " - " +
            row.ParentName +
            " - " +
            row.CategoryName;
          that.goodsCategory = "项目名称";
          api = "PerformanceSchemeProjectAll";
          break;
        case 3:
          that.GoodsTitle = "储值卡销售业绩取值" + " - " + row.CategoryName;
          that.goodsCategory = "储值卡名称";
          api = "PerformanceSchemeSavingCardAll";
          break;
        case 4:
          that.GoodsTitle = "时效卡销售业绩取值" + " - " + row.CategoryName;
          that.goodsCategory = "时效卡名称";
          api = "PerformanceSchemeTimeCardAll";
          break;
        case 5:
          that.GoodsTitle = "通用次卡销售业绩取值" + " - " + row.CategoryName;
          that.goodsCategory = "通用次卡名称";
          api = "PerformanceSchemeGeneralCardAll";
          break;
        case 6:
          that.GoodsTitle =
            "产品消耗业绩取值" +
            " - " +
            row.ParentName +
            " - " +
            row.CategoryName;
          that.goodsCategory = "产品名称";
          api = "PerformanceSchemeTreatProductAll";
          break;
        case 7:
          that.GoodsTitle =
            "项目消耗业绩取值" +
            " - " +
            row.ParentName +
            " - " +
            row.CategoryName;
          that.goodsCategory = "项目名称";
          api = "PerformanceSchemeTreatProjectAll";
          break;
      }
      API[api](params).then((res) => {
        if (res.StateCode == 200) {
          that.goodsPerformanceList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 商品明细业绩保存
    goodsDetailSave() {
      const that = this;
      const activeName = that.activeName;
      let api;
      let param = {
        PerformanceSchemeID: that.EditFormData.ID,
        CategoryID: that.goodsPerformanceID,
        Good: [],
      };
      if (!that.goodsPerformanceList.length) return;
      switch (activeName) {
        case "first":
          api = "PerformanceSchemeProductCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (
              item.PayPerformanceRate ||
              item.SavingCardPerformanceLargessRate ||
              item.SavingCardPerformanceRate
            ) {
              param.Good.push({
                GoodID: item.ID,
                PayPerformanceRate: item.PayPerformanceRate,
                SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                SavingCardPerformanceLargessRate:
                  item.SavingCardPerformanceLargessRate,
              });
            }
          });
          break;
        case "second":
          api = "PerformanceSchemeProjectCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (
              item.PayPerformanceRate ||
              item.SavingCardPerformanceLargessRate ||
              item.SavingCardPerformanceRate
            ) {
              param.Good.push({
                GoodID: item.ID,
                PayPerformanceRate: item.PayPerformanceRate,
                SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                SavingCardPerformanceLargessRate:
                  item.SavingCardPerformanceLargessRate,
              });
            }
          });
          break;
        case "third":
          api = "PerformanceSchemeGeneralSavingCardCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (item.PayPerformanceRate) {
              param.Good.push({
                GoodID: item.ID,
                PayPerformanceRate: item.PayPerformanceRate,
              });
            }
          });
          break;
        case "fourth":
          api = "PerformanceSchemeGeneralTimeCardCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (
              item.PayPerformanceRate ||
              item.SavingCardPerformanceLargessRate ||
              item.SavingCardPerformanceRate
            ) {
              param.Good.push({
                GoodID: item.ID,
                PayPerformanceRate: item.PayPerformanceRate,
                SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                SavingCardPerformanceLargessRate:
                  item.SavingCardPerformanceLargessRate,
              });
            }
          });
          break;
        case "five":
          api = "PerformanceSchemeGeneralCardCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (
              item.PayPerformanceRate ||
              item.SavingCardPerformanceLargessRate ||
              item.SavingCardPerformanceRate
            ) {
              param.Good.push({
                GoodID: item.ID,
                PayPerformanceRate: item.PayPerformanceRate,
                SavingCardPerformanceRate: item.SavingCardPerformanceRate,
                SavingCardPerformanceLargessRate:
                  item.SavingCardPerformanceLargessRate,
              });
            }
          });
          break;
        case "six":
          api = "PerformanceSchemeGeneralTreatProductCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (item.PerformanceRate || item.PerformanceLargessRate) {
              param.Good.push({
                GoodID: item.ID,
                PerformanceRate: item.PerformanceRate,
                PerformanceLargessRate: item.PerformanceLargessRate,
              });
            }
          });
          break;
        case "seven":
          api = "PerformanceSchemeGeneralTreatProjectCreate";
          that.goodsPerformanceList.forEach((item) => {
            if (item.PerformanceRate || item.PerformanceLargessRate) {
              param.Good.push({
                GoodID: item.ID,
                PerformanceRate: item.PerformanceRate,
                PerformanceLargessRate: item.PerformanceLargessRate,
              });
            }
          });
          break;
      }
      API[api](param).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "保存成功",
            duration: 2000,
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 显示弹层
    handleShow(type, row, opt) {
      const that = this;
      that[type] = true;
      switch (type) {
        // 编辑业绩取值方案弹层
        case "editDialogVisible":
          that.EditFormData.ID = row.ID;
          that.EditFormData.Active = row.Active;
          that.EditFormData.Name = row.Name;
          that.PerformanceSchemeDetail(row.ID);
          break;
        // 商品明细业绩弹层
        case "dialogDetailVisible":
          that.goodsPerformanceID = row.CategoryID;
          that.goodsPerformance(opt, row);
          break;
      }
    },
    // 保存弹层
    async handleSave(type) {
      const that = this;
      switch (type) {
        case "newDialogVisible":
          that.handleNewAdd();
          break;
        case "editDialogVisible":
          that.handleEditSave();
          break;
        case "dialogDetailVisible":
          that.goodsDetailSave();
          break;
      }
      await that.$nextTick();
      that[type] = false;
    },
    // 关闭弹层
    handleClose(type, ref) {
      const that = this;
      let isRef = false; //控制是否执行Ref清空表单
      that[type] = false; //关闭弹层

      switch (type) {
        // 编辑业绩取值方案弹层
        case "editDialogVisible":
          isRef = true;
          that.activeName = "first";
          that.EditFormData = {
            ID: "",
            Name: "",
            Active: true,
            ProductCategory: [],
            ProjectCategory: [],
            GeneralCardCategory: [],
            TimeCardCategory: [],
            SavingCardCategory: [],
            TreatProductCategory: [],
            TreatProjectCategory: [],
          };
          break;
        // 商品明细业绩弹层
        case "dialogDetailVisible":
          isRef = true;
          break;
      }
      if (isRef) return;
      that.$refs[ref].resetFields();
    },
  },
};
</script>

<style lang="scss" >
.SalaryPerformanceScheme {
  .editDialog {
    .el-input__inner {
      padding: 0 0 0 15px;
    }
  }
}
</style>